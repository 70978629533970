import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import PageTemplate from "./components/PageTemplate";
import HungerCrisesPage from "./pages/HungerCrisesPage";
import DonatePage from "./pages/DonatePage";
import ClimateCrisesPage from "./pages/ClimateCrisesPage";
import PaymentSuccessfulPage from "./pages/PaymentSuccessfulPage";
import PaymentFailPage from "./pages/PaymentFailPage";
import ContactPage from "./pages/ContactPage";

function App() {
  return (
    
      <Routes>
        <Route path="/" element={<PageTemplate />}>
          <Route
            index
            element={<HomePage companyName={"Children in Crises Foundation"} />}
          />
          <Route
            path="/emergencies/hunger-crises-donation"
            element={<HungerCrisesPage />}
          />
          <Route
            path="/emergencies/climate-crises-donation"
            element={<ClimateCrisesPage />}
          />
          <Route
            path="/donation/payment-fail"
            element={<PaymentFailPage />}
          />
          <Route
            path="/donation/payment-suceessful"
            element={<PaymentSuccessfulPage />}
          />
          <Route path="/relief/donate" element={<DonatePage />} />
          <Route path="/contact-us" element={<ContactPage />} />
        </Route>
      </Routes>
  );
}

export default App;
