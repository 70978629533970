import { Link } from "react-router-dom";

export default function PaymentSuccessfulPage() {
  return (
    <div className='payment-status-page'>
        <div className="page-content-center">
            <h1>Thank You for Your Donation!</h1>
            <p>Your kindness is making a meaningful difference in the lives of those in need. Together, we are creating positive change and fostering hope for a brighter future.</p>
            <Link to="/">Go back to homepage</Link>
        </div>
    </div>
  )
}
