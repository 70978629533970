import React from "react";
import { Link } from "react-router-dom";

export default function Footer({companyName = ""}) {
  return (
    <footer>
     <div >
     <Link to={"/contact-us"} className="contact-footer">Contact Us</Link>
      <p>&copy; 2023 {companyName}</p>
     </div>
    </footer>
  );
}
