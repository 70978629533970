
export default function Header() {
  return (
    <header>
      <a href="/" className="logo">
        Children in crises foundation
      </a>
      <a href="/relief/donate" className="hd-donate">
        Donate
      </a>
    </header>
  );
}
