import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function CrisesHero({ image, description, navigateTo }) {
  const navigate = useNavigate();

  function handleOnclick() {
    navigate(navigateTo);
  }
  return (
    <>
      <div className="crises-item" onClick={handleOnclick}>
        <div className="image">
          <img src={`/${image}`} alt="" />
        </div>
        <p className="hero-action">{description}</p>
        <Link to={"/relief/donate"} className="item-donate">
          Donate here
        </Link>
      </div>
    </>
  );
}
