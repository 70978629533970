import React from "react";
import { Link } from "react-router-dom";

export default function PaymentFailPage() {
  return (
    <>
      <div className="payment-status-page">
        <div className="page-content-center">
          <h1 className="p-error">Payment Unsuccessful</h1>
          <p>
            We're sorry, but it seems there was an issue processing your
            donation. We appreciate your generosity and apologize for any
            inconvenience. Please review your payment details and try again.
          </p>
          <Link to={'../relief/donate'}>Try again</Link>
        </div>
      </div>
    </>
  );
}
