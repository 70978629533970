import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function DonatePage() {

  // form
  const [monthlyPayment, setMonthlyPayment] = useState(true);
  const [donationAmount, setDonationAmount] = useState(30);
  const [donationCurrency, setDonationCurrency] = useState("USD");
  const [donorFullName, setDonorFullname] = useState("");
  const [donorEmail, setDonorEmail] = useState("");
  const [donorPhone, setDonorPhone] = useState("");
  const fullnameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const userLocale = navigator.language;

  // form
  const handleMonthlyPaymentTrue = () => {
    setMonthlyPayment(true);
    // console.log(monthlyPayment)
  };
  const handleMonthlyPaymentFalse = () => {
    setMonthlyPayment(false);
    // console.log(monthlyPayment)
  };

  const handleDonationAmount = (amount) => {
    setDonationAmount(amount);
  };
  const handleDonationCurrency = (string) => {
    setDonationCurrency(string);
  };

  const formatCurrency = (value, currency) => {
    return new Intl.NumberFormat(userLocale, {
      style: "currency",
      currency: currency, // Replace with your target currency code
    }).format(value);
  };


  function handleFormSubmit() {

    console.log(monthlyPayment)

    // remove error class
    fullnameInputRef.current.classList.remove("error-border");
    emailInputRef.current.classList.remove("error-border");

    // Check if the text input field is empty
    if (donorFullName.trim() === "") {
      // console.log("Donor name is required.");
      fullnameInputRef.current.classList.add("error-border");
      fullnameInputRef.current.focus();
      return;
    }
    if (donorEmail.trim() === "") {
      // console.log("Donor email is required.");
      emailInputRef.current.classList.add("error-border");
      emailInputRef.current.focus();
      return;
    }
    handlePayment()
  }

  // create payment intent
  async function handlePayment() {
    fetch("https://sdcv-tyru.biz/create-subscription-session", {
      method: "POST",
      body: JSON.stringify({
        name: donorFullName,
        email: donorEmail,
        amount: donationAmount,
        currency: donationCurrency,
        is_monthly_paymennt: monthlyPayment
      }),
      headers: { "Content-type": "application/json" }
    }).then(async (result) => {
      const data = await result.json();
      console.log(data);
      if (data.url) {
        window.location.href = data.url;
      }
    });
  }

  useEffect(() => {
    setDonationAmount(30);
    // console.log(monthlyPayment)
  }, []);


  return (
    <>
      <div className="donation-page">
        <div className="left">
          <h2>Take Action</h2>
          <p>
            With your support, we can reach more children and their families
            with life-saving food assistance, medical care and livelihood
            support.
          </p>
          <h3>Make a difference today, become a donor!</h3>
          <div className="step1">

            <fieldset>
              <legend>Yes, I want to donate</legend>
              <div className="i-input-radio-group">
                <div className="i-choice i-radio">
                  <input
                    type="radio"
                    name="input-c"
                    id="choice-f-0"
                    checked={monthlyPayment}
                    onChange={() => handleMonthlyPaymentTrue()}
                  />
                  <label htmlFor="choice-f-0">Monthly</label>
                </div>
                <div className="i-choice i-radio">
                  <input
                    type="radio"
                    name="input-c"
                    id="choice-f-1"
                    onChange={() => handleMonthlyPaymentFalse()}
                  />
                  <label
                    htmlFor="choice-f-1"
                  // onClick={() => handleMonthlyPayment(false)}
                  >
                    One-time
                  </label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>How much do you want to donate?</legend>
              <div className="i-input-radio-group">
                {[10, 30, 50, 100, 200, 500].map((amount) => (
                  <div key={amount} className="i-choice i-radio">
                    <input
                      type="radio"
                      name="input-b"
                      id={`choice-b-${amount}`}
                      checked={donationAmount === amount}
                      onChange={() => handleDonationAmount(amount)}
                    />
                    <label htmlFor={`choice-b-${amount}`}>{amount}</label>
                  </div>
                ))}
              </div>
            </fieldset>
            <fieldset>
              <legend>Currency</legend>
              <div className="i-input-radio-group">
                {["USD", "EUR", "GBP"].map((currency) => (
                  <div key={currency} className="i-choice i-radio">
                    <input
                      type="radio"
                      name="currency-b"
                      id={`currency-b-${currency}`}
                      checked={donationCurrency === currency}
                      onChange={() => handleDonationCurrency(currency)}
                    />
                    <label htmlFor={`currency-b-${currency}`}>{currency}</label>
                  </div>
                ))}
              </div>
            </fieldset>
            <fieldset>
              <legend>I am</legend>
              <div className="i-input-form-group">
                <div className="text-input">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Full name"
                    value={donorFullName}
                    onChange={(e) => setDonorFullname(e.target.value)}
                    ref={fullnameInputRef}
                  />
                </div>
                <div className="text-input">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="E-mail address"
                    value={donorEmail}
                    onChange={(e) => setDonorEmail(e.target.value)}
                    ref={emailInputRef}
                  />
                  <p>You will receive an email for confirmation.</p>
                </div>
                <div className="text-input">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Mobile number"
                    value={donorPhone}
                    onChange={(e) => setDonorPhone(e.target.value)}
                    ref={phoneInputRef}
                  />
                </div>
              </div>
            </fieldset>



            <fieldset>
              <span>
                <h4>
                  {monthlyPayment ? <>Monthly:</> : <>One-time:</>}{" "}
                  {donationCurrency === "USD" && (
                    <>{formatCurrency(donationAmount, "USD")}</>
                  )}
                  {donationCurrency === "EUR" && (
                    <>{formatCurrency(donationAmount, "EUR")}</>
                  )}
                  {donationCurrency === "GBP" && (
                    <>{formatCurrency(donationAmount, "GBP")}</>
                  )}
                </h4>
              </span>
            </fieldset>
          </div>


          <div className="action-btn-group">
            <Link onClick={(e) => handleFormSubmit()} >
              Continue
            </Link>
          </div>

        </div>
        <div className="right">
          <p></p>
        </div>
      </div>
    </>
  );
}
