import { Link } from "react-router-dom";
import CrisesHero from "../components/CrisesHero";
export default function ClimateCrisesPage() {
  return (
    <>
      <CrisesHero
        description={"STOP THE CHILDREN'S FOOD CRISIS"}
        image={"hunger.jpg"}
      />
      <div className="donate-w100">
        <Link to={'../relief/donate'}>Donate now</Link>
      </div>
      <section className="crises-description">
        <h2>Join the Fight Against Climate Change</h2>
        <p>
        As the effects of climate change intensify, children around the world find themselves on the frontlines of an unprecedented crisis. Rising temperatures, extreme weather events, and environmental degradation threaten their safety, health, and future. At Children in Crises Foundation, we are committed to safeguarding the planet and the children who call it home.
        </p>
        <h3>Our Mission</h3>
        <p>
        Children in Crises Foundation stands as a beacon of hope in the face of climate change. Our mission is to protect vulnerable communities, especially children, from the devastating impacts of environmental degradation. Through sustainable initiatives, advocacy, and education, we strive to build a resilient future for the generations to come.
        </p>
        <h3>The Urgency of Action</h3>
        <ul>
          <li>
            <b>Climate Resilience:</b> Your donation can contribute to projects aimed at building climate-resilient communities, ensuring children have a secure environment despite the challenges.
          </li>

          <li>
            <b>Reforestation Programs: </b> Support initiatives to plant trees and restore ecosystems, contributing to a healthier planet for current and future generations.
          </li>
          <li>
            <b>Renewable Energy: </b> Promote the adoption of renewable energy sources, reducing carbon footprints and creating a sustainable future.
          </li>
        </ul>
        <h3>How Your Support Makes a Difference</h3>
        <ul>
          <li>
            <b>Clean Water Access: </b>Climate change affects water availability. Your contribution can help provide access to clean and safe water for communities in need.
          </li>

          <li>
            <b>Education for Sustainability: </b>Empower children and communities with knowledge about sustainable practices, fostering a sense of responsibility for the environment.
          </li>

          <li>
            <b>Emergency Response:</b> Climate-related disasters demand swift response. Your support can fund emergency relief efforts, ensuring affected children receive immediate assistance.
          </li>
        </ul>
        <h3>Be the Change: Act Today</h3>
        <ul>
          <li>
            <b>One-Time Donation: </b>Make an immediate impact by supporting our ongoing initiatives to combat climate change.
          </li>

          <li>
            <b>Monthly Giving: </b>Join our community of monthly donors, providing continuous support for long-term sustainability projects.
          </li>

          <li>
            <b>Advocate for Change: </b>Raise awareness about the impact of climate change and inspire others to take action for a sustainable future.
          </li>
        </ul>
      </section>
    </>
  );
}
