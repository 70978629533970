import React from "react";
import CrisesHero from "../components/CrisesHero";
import { Link } from "react-router-dom";
export default function HomePage({ companyName = "" }) {
  return (
    <>
      <div className="hero">
        <div className="image">
          <img src="/image1.jpg" alt="" />
        </div>
        <Link to="/relief/donate" className="hero-action">
          Providing Hope in Times of Crisis
        </Link>
        <Link to="/relief/donate" className="hr-donate">
          Donate here
        </Link>
      </div>
      <div className="crises-list">
        <CrisesHero
          image={"hunger.jpg"}
          description={"STOP THE CHILDREN'S FOOD CRISIS"}
          navigateTo={"/emergencies/hunger-crises-donation"}
        />
        <CrisesHero
          image={"climate.jpg"}
          description={"THE CLIMATE CRISIS"}
          navigateTo={"/emergencies/climate-crises-donation"}
        />
      </div>

      <section className="home">
        <h2>our mission</h2>
        <p>
          At {companyName}, we believe in the power of hope, compassion, and
          support. We are dedicated to making a difference in the lives of
          vulnerable children and mothers. Our mission is to empower and uplift
          them through crises relief, education, healthcare, and social support.
        </p>
        <h2>we've helped...</h2>
        <p>
          1.7 million children across the world get the medicine, good food,
          shelter and education they need
        </p>
        <h2>Join Us in Making a Difference</h2>
        <p>
          Every child and mother deserves the opportunity to lead a healthy,
          fulfilling, and empowered life. Together, we can make this vision a
          reality. Explore our programs and get involved in our cause today. <Link to={'/donor'} className="donor-link-btn">Become a donor</Link>
        </p>
        {/* <Link to={'/donor'} className="btn-home-donor">Become a Donor</Link> */}
      </section>
    </>
  );
}
