import { Link } from "react-router-dom";
import CrisesHero from "../components/CrisesHero";
export default function HungerCrisesPage() {
  return (
    <>
      <CrisesHero
        description={"STOP THE CHILDREN'S FOOD CRISIS"}
        image={"hunger.jpg"}
      />
      <div className="donate-w100">
      <Link to={'../relief/donate'}>Donate now</Link>
      </div>
      <section className="crises-description">
        <h2>HELP END THE GLOBAL HUNGER CRISIS</h2>
        <p>
          Every day, millions of people around the world go to bed hungry,
          facing the harsh reality of food insecurity. The global hunger crisis
          is an urgent and complex issue that requires immediate attention. In a
          world abundant with resources, it is unacceptable that so many still
          suffer from malnutrition and hunger
        </p>
        <h3>Our Mission</h3>
        <p>
          we are dedicated to combating the world hunger crisis head-on. We
          believe that no one should ever have to experience the pain of an
          empty stomach. Our mission is to provide sustainable solutions,
          support vulnerable communities, and work towards a world where every
          individual has access to nutritious food.
        </p>
        <h3>Why Your Support Matters</h3>
        <ul>
          <li>
            <b>Saving Lives:</b> Your donation directly contributes to
            life-saving efforts, providing food to those who need it most.
          </li>

          <li>
            <b>Empowering Communities:</b> We focus on long-term solutions,
            empowering communities to grow their own food, access clean water,
            and develop sustainable agricultural practices.
          </li>
          <li>
            <b>Education and Awareness:</b> We believe in the power of education
            to break the cycle of hunger. Your support helps us raise awareness
            and educate communities about nutrition and sustainable farming
            practices.
          </li>
        </ul>
        <h3>How You Can Help</h3>
        <ul>
          <li>
            <b>Donate Today:</b> Every dollar counts. Your contribution helps us
            provide immediate relief and invest in long-term solutions.
          </li>

          <li>
            <b>Spread the Word:</b> Share our mission with your friends, family, and social networks. Together, we can amplify our impact and reach those who can make a difference.
          </li>

          <li>
            <b>Volunteer:</b> Join us in the field or contribute your skills. Volunteers play a crucial role in our efforts to combat hunger.
          </li>
        </ul>
      </section>
    </>
  );
}
