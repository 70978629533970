
export default function PageHero({ image, title, description }) {
    return (
        <div className="page-hero">
            <div className="image">
                <img src={`/${image}`} alt="" />
            </div>
            <div className="text">
            <h2 className="">{title}</h2>
            <p>{description}</p>
            </div>
        </div>
    )
}
