import React from 'react'
import PageHero from '../components/PageHero'

export default function ContactPage() {
    return (
       <>
       <PageHero image={'contactus.jpg'} title="Send us a message" description={"If you've got some feedback or have a question you'd like us to answer we'd like to hear from you."}/>
        <div className='contact-page'>
            <p className='h1-big'>Get in touch </p>
            <h3>We'd love to hear from you. If you have feedback or questions, you're always welcome to get in touch.</h3>

            <section className='crises-description'>
                <ul>
                    <li>
                        Email us info@savethechildren.org.au  or send us a message online
                    </li>

                    <li>
                        Phone us on 1800 76 00 11. We'll be there to take your call between 9am–5pm Monday to Friday
                    </li>

                    <li>
                    Post us a letter at Save the Children Australia, 33 Lincoln Square South, Carlton, 3053, Victoria
                    </li>
                </ul>
            </section>
        </div></>
    )
}
